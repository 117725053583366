vm-playback-control {
    background-color: white;
    border-radius: 50px;
    --vm-control-color: #606060;
    --vm-control-focus-color: #606060;
    --vm-control-scale: 0.5;

}

@media(max-width: 1280px) {
    vm-playback-control {
       scale: 1.2 !important;
    }
}

@media(max-width: 820px) {
    vm-playback-control {
        scale: 1;
    }
}
