/*
        This font software is the property of Commercial Type.

        You may not modify the font software, use it on another website, or install it on a computer.

        License information is available at http://commercialtype.com/eula
        For more information please visit Commercial Type at http://commercialtype.com or email us at info[at]commercialtype.com

        Copyright (C) 2021 Schwartzco Inc.
        License: 2105-DJQRDG     
*/


@font-face {
  font-family: 'Publico';
  src: url('PublicoBanner-Medium-Web.woff2') format('woff2'),
       url('PublicoBanner-Medium-Web.woff') format('woff');
  font-weight:  500;
  font-style:   normal;
  font-stretch: normal;
}

.PublicoBanner-Medium-Web {
  font-family: 'Publico';
  font-weight:  500;
  font-style:   normal;
  font-stretch: normal;
}

