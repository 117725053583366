.slick-dots li {
    margin: auto;
   }
   
   .slick-dots li.slick-active button:before{
       color: #0083FF;
       opacity: 1;
       font-size: 8px;
   }
   
   .slick-dots li button:before{
       color: #E0E1E2;
       opacity: 1;
       font-size: 8px;
   }
   
   @media only screen and (max-width: 600px) {
       .slick-dots li button{
           height: 50px;
       }
   }