@import url('../assets/fonts/apercu-medium-pro/stylesheet.css');
@import url('../assets/fonts/apercu-regular-pro/stylesheet.css');
@import url('../assets/fonts/Recoleta/demo.css');
@import url('../assets/fonts/Publico/fonts.css');
@import url('currency-flags/dist/currency-flags.css');


#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

* {
  font-family: 'Apercu Pro', Helvetica Neue, Arial, sans-serif;
  -webkit-tap-highlight-color: transparent;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

button:focus {
  outline: 0;
}

a:hover{
  text-decoration: none;
}

a{
  text-decoration: none;
}

.slider-item:focus {
  outline: 0;
}

input:focus {
  outline: 0;
}

textarea:focus, select:focus {
  outline: 0;
}

/* HTML5 display-role reset for older browsers */

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1.4;
}

ol, ul {
  list-style: none;
}

button:focus {
  outline: none;
}

.row {
  margin-right: 0px;
  margin-left: 0px;
}

@keyframes spin {
  from {
      transform:rotate(0deg);
  }
  to {
      transform:rotate(360deg);
  }
}

.spinner{
  margin: 0 auto;
  animation-name: spin;
  animation-duration: 5000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear; 
}

html {
  scroll-behavior: smooth;
}

:focus-visible{
  outline: none;
}